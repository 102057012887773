
<template>

  <v-main style="background-color:#F2F3F8">
    <v-container>

      <v-row>
          <v-col
            cols="12"
            md="2">

            <v-row>

              <v-col>

           
              <v-sheet
              rounded="lg"
              elevation="1"
              min-height="268"
             
              style="padding:13px">
              
              <div >

                <v-list-item style="margin-left:-13px">
                  <v-icon color="primary" >mdi-wallet</v-icon>
                   <h2 class="ml-2">Transfer Token</h2>
                </v-list-item>

<!-- :prepend-inner-icon="(isAlreadyUsed && isAccountValidShow) ? 'mdi-check-circle':'mdi-close-circle'" -->

                <form style="margin:10px">
                <v-text-field
                  v-model="ttSendTo"
                  :counter="12"
                  :rules="[ rules.onlyEnlglishAndNumber]"
                  label="Send To"
                  required
                  outlined
                 @keyup="afterAccountNameChanged"
                 >
              <template v-slot:prepend-inner>
             
                <v-icon
                  width="24"
                  height="24"
                  :color=" isAccountValidShow ? (isAlreadyUsed  ? 'green':'red' ) : 'grey'">
                  {{
                    isAccountValidShow ? ( isAlreadyUsed ? 'mdi-check-circle':'mdi-close-circle') :'mdi-help-circle-outline'
                  }}
                </v-icon>
            </template>

                </v-text-field>

                <!-- <p v-show="isAlreadyUsed && isAccountValidShow" class="green--text"><v-icon color="green">mdi-check-circle</v-icon></p>
                <p v-show="!isAlreadyUsed && isAccountValidShow" class="red--text"><v-icon color="red">mdi-close-circle</v-icon></p>  -->

                <v-text-field
                  v-model="ttAmount"
                  label="Amount(EOS)"
                  required
                  outlined >
                  </v-text-field>

                <v-text-field
                  v-model="ttMemo"
                  outlined
                  
                  label="Memo(Optional)"
                  
                ></v-text-field>
                
                <v-row>

                  <v-btn
                    class="mr-4 ml-3"
                    outlined
                    color="primary"
                    @click="anchorSendToken"
                    :loading="isTokenSendLoading"
                    :disabled="!($store.getters.isAnchorLogined && isTokenSendable)"
                    small>
                    Send
                  </v-btn>

                  <v-btn class="mr-4 " outlined
                  @click="showDialogMultiTokenSend" 
                  :disabled="!($store.getters.isAnchorLogined)" small>
                    MultiSend
                  </v-btn>

                </v-row>
 
                </form>
              
              </div>
            
            </v-sheet>

              </v-col>
        
            </v-row>

             <v-row>

              <v-col>

                <v-sheet
                    rounded="lg"
             elevation="1"
                    min-height="225"
                    style="padding:13px"
                  >
                    <v-list-item style="margin-left:-13px">
                     <v-icon   color="primary"  >mdi-flash</v-icon>
                     <h2 class="ml-2">Free Power Up</h2>
                    </v-list-item>

                  <v-text-field
                   style="margin:10px"
                  v-model="powerUpID"
                  :counter="12"
                  label="Send To"
                  required
                  outlined>
                   
                  </v-text-field>

                  <v-btn  outlined
                    color="primary" class="mr-4 ml-3" @click="powerUp" :disabled="isPowerUpDisable"  
                         :loading="isPowerUpLoading" small>Free</v-btn>
                  <!-- <v-btn class="mr-4" @click="powerUpWithAccount"  :disabled="!$store.getters.isAnchorLogined" >Self</v-btn> -->

                  <v-tooltip bottom color="warning">
                      <template v-slot:activator="{ on, attrs }">
                        
                         <v-btn class="mr-4"
                          outlined
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          @click="anchorPayPowerUp"  :disabled="(!$store.getters.isAnchorLogined || isPowerUpDisable)" small >Pay</v-btn>

                      </template>
                      <span>It may costs 0.0007 EOS.(Max Limit : 0.0027 EOS) </span><br>
                      <span>You can transfer 2-3 times in 1 day</span>
                  </v-tooltip>
                 
                </v-sheet>

              </v-col>
        
            </v-row>
 

          </v-col>

          <v-col
            cols="12"
            md="8">

            <v-row>

              <v-col>

               <v-sheet
                min-height="45vh"
                rounded="lg"
                elevation="1"
                style="padding:13px">

              <v-list-item style="margin-left:-13px">
                     <v-icon   color="primary"  >mdi-shield-account</v-icon>
                     <h2 class="ml-2">Account Info</h2>
                    </v-list-item>


              <v-row >
                <v-col  cols="12" sm="9">
                  <v-text-field  
                     class="ml-4"  :counter="12" v-model="searchAccountName" label="Account Name" v-on:keyup.enter="getAccountInfo(searchAccountName)"></v-text-field>
                </v-col>
                 <v-col   cols="12" sm="2">

                  <v-container fill-height>
                     <div  >
                     <v-btn outlined  color="primary" @click="getAccountInfo(searchAccountName)" :loading="isAccountSearchLoading">Search</v-btn>
                   </div>
                   

                  </v-container>

                 
                 </v-col>
              </v-row>

              <v-row>


              <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title>

                          <div  class="d-flex justify-start align-center">

                            <strong><h1>{{searchedAccountName}}</h1></strong>  
                            <v-chip class="ml-4 text-h6"
                            color="amber darken-2"
                            label
                            outlined
                            v-show="searchedAccountData.is_proxy!=0">
                            voting proxy
                          </v-chip>

                          </div>
 
                       </v-list-item-title>
                    <v-list-item-subtitle style="margin-left:10px">Created ({{accountInfoCreatedDate}}) </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <!-- <v-col>

                </v-col>

                <v-col>
                </v-col> -->
                
                <!-- <p class="text-h5 ml-6">Total Balance <span style="color:#2196F3">{{searchedAccountData.balance_total.toLocaleString()}}</span> EOS ≈ <span style="color:#4DB6AC">${{searchedAccountData.total_value.toLocaleString()}}</span> USD (${{searchedAccountData.eos_price}})</p> -->
                <p class="text-h5 ml-6">Total Balance <span style="color:#2196F3">{{searchedAccountData.balance_total}}</span> EOS </p>

              </v-row>
               
             <v-row>

               <v-col>

                 <v-simple-table>
                <template >
                  <tbody>
                    
                    <tr>
                      <td>Available:</td>
                      <td>{{searchedAccountData.balance_unstaked}}</td>
                    </tr>
                    
                     <tr>
                      <td>Refunding:</td>
                      <td>{{searchedAccountData.balance_refund}} EOS</td>
                    </tr>
                     <tr>
                      <td>CPU Staked:</td>

                      <v-tooltip bottom color="black">
                        <template v-slot:activator="{ on, attrs }" >
                          <td   v-bind="attrs" v-on="on">{{ parseFloat(searchedAccountData.cpu_self + searchedAccountData.cpu_selfToOthers).toFixed(4) }} EOS</td>
                        </template>
                      <span>self staked : {{searchedAccountData.cpu_self}} EOS</span><br>
                      <span>staked to others: {{searchedAccountData.cpu_selfToOthers}} EOS</span>
                     </v-tooltip>
 
                      
                    </tr>
                     <tr>
                      <td>NET Staked:</td>

                       <v-tooltip bottom color="black">
                        <template v-slot:activator="{ on, attrs }" >
                          <td   v-bind="attrs" v-on="on">{{ parseFloat(searchedAccountData.net_self + searchedAccountData.net_selfToOthers).toFixed(4)}} EOS</td>
                        </template>
                      <span>self staked : {{searchedAccountData.net_self}} EOS</span><br>
                      <span>staked to others: {{searchedAccountData.net_selfToOthers}} EOS</span>
                     </v-tooltip>

                    </tr>
                     <tr>
                      <td>Staked by Others:</td>

                      <v-tooltip bottom color="black">
                        <template v-slot:activator="{ on, attrs }" >
                          <td   v-bind="attrs" v-on="on">{{ accountInfoOtherStaked}} EOS</td>
                        </template>
                      <span>CPU Staked by Others : {{accountInfoOtherStakedOnlyCPU}} EOS</span><br>
                      <span>NET Staked by Others : {{accountInfoOtherStakedOnlyNET}} EOS</span>
                     </v-tooltip>


                     
                    </tr>
                    <tr>
                      <td>Total REX:</td>
                      <!-- <td>{{searchedAccountData.rex_total_balance.toFixed(4)}} EOS</td> -->
                      <td>{{searchedAccountData.rex_total_balance}}</td>
                    </tr>
                  </tbody>
                </template>
                 </v-simple-table>
               </v-col>
               
               
               <v-col class="d-none d-sm-flex">

                <v-container fill-height>
                    <v-row justify="center" align="center">
                        <v-col  >
                            <h5>RAM:</h5>
                             <v-progress-linear
                        
                              :value="accountInfoRamPercent"
                              rounded
                              height="25"
                              color="teal lighten-2"
                              
                            >
                            
                               <strong style="font-size:12px;color:black;">
                              {{accountInfoRamPercent}}%
                              </strong> 
                        </v-progress-linear>
                       

                        <p class="text-center" style="font-size:14px">
                              RAM used - {{getDan_ram_usage}}
                        </p>

                        
                         <h5>CPU:</h5>
                         <v-progress-linear
                        
                               :value="accountInfoCPUPercent"
                              rounded
                              height="25"
                              
                            >
                              <strong style="font-size:12px;color:black">
                              {{accountInfoCPUPercent}}%
                              </strong>
                        </v-progress-linear>

                         <p class="text-center" style="font-size:14px">
                            CPU used - {{getDan_cpu_usage}}
                        </p>


                         <h5>NET:</h5>
                         <v-progress-linear
                       
                              :value="accountInfoNETPercent"
                              rounded
                              height="25"
                              color="green accent-4"
                            >
                              <strong style="font-size:12px;color:black">
                              {{accountInfoNETPercent}}%
                              </strong>
                        </v-progress-linear>
                        <p class="text-center" style="font-size:14px">
                            NET used - {{getDan_net_usage}}
                        </p>


                        </v-col>
                    </v-row>
                </v-container>
 
               </v-col>

             </v-row>

          


            <v-row class="ma-2 d-flex d-sm-none">

              <v-container fill-height>
                    <v-row justify="center" align="center">
                        <v-col  >
                            <h5>RAM:</h5>
                             <v-progress-linear
                        
                              :value="accountInfoRamPercent"
                              rounded
                              height="25"
                              color="teal lighten-2"
                              
                            >
                            
                               <strong style="font-size:12px;color:black;">
                              {{accountInfoRamPercent}}%
                              </strong> 
                        </v-progress-linear>
                       

                        <p class="text-center" style="font-size:14px">
                              RAM used - {{getDan_ram_usage}}
                        </p>

                        
                         <h5>CPU:</h5>
                         <v-progress-linear
                        
                               :value="accountInfoCPUPercent"
                              rounded
                              height="25"
                              
                            >
                              <strong style="font-size:12px;color:black">
                              {{accountInfoCPUPercent}}%
                              </strong>
                        </v-progress-linear>

                         <p class="text-center" style="font-size:14px">
                            CPU used - {{getDan_cpu_usage}}
                        </p>


                         <h5>NET:</h5>
                         <v-progress-linear
                       
                              :value="accountInfoNETPercent"
                              rounded
                              height="25"
                              color="green accent-4"
                            >
                              <strong style="font-size:12px;color:black">
                              {{accountInfoNETPercent}}%
                              </strong>
                        </v-progress-linear>
                        <p class="text-center" style="font-size:14px">
                            NET used - {{getDan_net_usage}}
                        </p>


                        </v-col>
                    </v-row>
                </v-container>

            </v-row>


             </v-sheet>


              </v-col>

            </v-row>

            <!-- 기존 트랜잭션 히스토리 ( 이오스플레어 CORS 제한으로 사용안함) -->
            <v-row v-if="false">
              <v-col>
                <v-sheet
                min-height="70vh"
                rounded="lg"
                elevation="1"
                style="padding:13px">

                <!-- <h3 class="ma-1">Transactions</h3> -->

                 <v-list-item style="margin-left:-13px">
                     <v-icon color="primary"  >mdi-bank-transfer</v-icon>
                     <h2 class="ml-2">Transactions</h2>
                    </v-list-item>


                <v-card elevation="0" class="mt-3" :loading="tranLoading">

                 <v-simple-table v-show="!tranLoading">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left text-h6">
                          TX
                        </th>
                        <th class="text-left text-h6">
                          Date
                        </th>
                         <th class="text-left text-h6">
                          Action
                        </th>
                         <th class="text-left text-h6">
                          Data
                        </th>
                      </tr>
                       
                    </thead>

                     <tbody  class="hidden-sm-and-down">

                      <tr v-for="item in transactions"
                        :key="item.seq">

                          <td style="color:#0277BD">
                           {{ item.tx }}

                           <v-icon color="orange" small v-show="!item.irreversible">
                             mdi-clock
                           </v-icon>
                           
                        </td>

                        <td>
                          <v-chip
                            label
                            color="white"
                            text-color="black"
                            outlined> {{ getTimezoneDate(item.date) }}</v-chip>
                         
                        </td>

                        <td >
                          <v-chip
                            v-if="item.actionType == 1"
                            color="green"
                            text-color="white"
                            label
                            pill
                          >
                             {{ item.action }}
                          </v-chip>
                          <v-chip
                            v-else-if="item.actionType == 0"
                            color="red"
                            text-color="white"
                            label
                            pill
                          >
                             {{ item.action }}
                          </v-chip>
                          <v-chip 
                            v-else-if="item.actionType == 3"
                            color="purple"
                            label
                            outlined
                          > {{ item.action }}</v-chip>
                           <v-chip 
                              v-else-if="item.actionType == 4"
                              color="blue accent-4"
                              label
                              outlined
                              class="mt-2"
                            > {{ item.action }}</v-chip>

                          <v-chip
                            v-else
                            color="grey darken-3"
                            label
                            outlined
                          >
                             {{ item.action }}
                          </v-chip>
                          
                       
                        </td>
                        
                        <td v-if="(item.actionType == 2 && item.tokenQuantity =='') || item.actionType == 3 || item.actionType == 4" 
                             class="pa-4" 
                             style="text-align:justify">
                         
                         
                           <pre class="text-subtitle-1">{{item.data}} </pre>
                        
                        </td>

                        <td v-else class="pa-4">


                            <div  class="d-flex justify-start align-center  " >

                               <!-- <pre class="text-subtitle-1">{{item.data}} </pre> -->


                              <pre class="text-subtitle-1" style='color: #0277BD'>{{item.from}} </pre>  → <pre style='color: #0277BD' class="text-subtitle-1"> {{item.to}} </pre>


                              <v-chip class="ml-4"
                              color="grey darken-1"
                              label
                              small
                              text-color="black"
                              outlined>
                                <strong> {{ item.tokenQuantity }} </strong>
                              </v-chip>

                            </div>

                            <!-- <pre class="text-caption mt-1">(Memo:{{item.actionMemo}})</pre> -->
                            <p class="mt-1">(Memo:{{item.actionMemo}})</p>
  
                        </td>
                      </tr>
                    </tbody>


                    <tbody  class="d-sm-none">

                      <tr v-for="item in transactions"
                        :key="item.seq">

                      <v-divider></v-divider>
                      
                        <div class="ma-7">

                          <v-row>

                            <div style="color:#0277BD">

                            {{ item.tx }}

                            <v-icon color="orange" small v-show="!item.irreversible">
                              mdi-clock
                            </v-icon>
                            
                          </div>
                          </v-row>

                          <v-row>

                          <div class="mt-2">
                            <!-- <v-chip
                              label
                              color="white"
                              text-color="black"
                              outlined> {{ getTimezoneDate(item.date) }}</v-chip> -->
                              <!-- <h5 class="ma-0"> </h5> -->
                            
                              {{ getTimezoneDate(item.date) }}
                          
                          </div>

                          </v-row>

                          <v-row>

                          <div class="ma-2">
                            <v-chip
                              v-if="item.actionType == 1"
                              color="green"
                              text-color="white"
                              label
                              pill
                              class="mt-2"
                            >
                              {{ item.action }}
                            </v-chip>
                            <v-chip
                              v-else-if="item.actionType == 0"
                              color="red"
                              text-color="white"
                              label
                              pill
                              class="mt-2"
                            >
                              {{ item.action }}
                            </v-chip>
                            <v-chip 
                              v-else-if="item.actionType == 3"
                              color="purple"
                              label
                              outlined
                              class="mt-2"
                            > {{ item.action }}</v-chip>
                            <v-chip 
                              v-else-if="item.actionType == 4"
                              color="blue accent-4"
                              label
                              outlined
                              class="mt-2"
                            > {{ item.action }}</v-chip>

                            <v-chip
                              v-else
                              color="grey darken-3"
                              label
                              outlined
                              class="mt-2"
                            >
                              {{ item.action }}
                            </v-chip>
                            
                        
                          </div>

                          </v-row>
                          
                          <v-row>
                          <td v-if="(item.actionType == 2 && item.tokenQuantity =='') || item.actionType == 3 || item.actionType == 4" 
                              class="pa-2" 
                              style="text-align:justify">
                          
                          
                            <pre class="text-subtitle-1">{{item.data}}</pre>
                          
                          </td>
  
                          <td v-else class="mt-1">


                              <div  class="d-flex justify-start align-center" >

                                <!-- <pre class="text-subtitle-1">{{item.data}} </pre> -->


                                <pre class="text-subtitle-1" style='color: #0277BD'>{{item.from}} </pre>  → <pre style='color: #0277BD' class="text-subtitle-1"> {{item.to}} </pre>
                                
                                <v-chip class="ml-2"
                                color="grey darken-1"
                                label
                                small
                                text-color="black"
                                outlined>
                                  <strong> {{ item.tokenQuantity }} </strong>
                                </v-chip>

                              </div>

                              <p class="mt-1">(Memo:{{item.actionMemo}})</p>
    
                          </td>
                        
                            
                          </v-row>

                        </div>
                      
                      </tr>
                    </tbody>


                  </template>
                </v-simple-table>

                <v-skeleton-loader v-show="tranLoading"
                  type=" table-heading, table-thead, table-tbody"
                ></v-skeleton-loader>

                </v-card>

                <v-divider></v-divider>

                <v-container  fill-width>
                   <div  class="d-flex justify-end align-center mb-1">

                       <div class="mr-2 font-italic"  >Items per page: {{tranItemPerPage}}  {{getPageRange}} of {{tranMaxCount}}</div>
                        <v-btn  outlined fab x-small color="primary" 
                                class="mr-2" 
                                @click="tranLeft"
                                :disabled="isPrePageEnable">  
                          <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                        <v-btn  outlined fab x-small color="primary" 
                                smallclass="mr-2" 
                                :disabled="isNexPageEnable"
                                @click="tranRight">  
                          <v-icon>mdi-arrow-right</v-icon>
                        </v-btn>
 
                    </div>
                </v-container>

               </v-sheet>

              </v-col>
              
            </v-row>

             <v-row >
              <v-col>
                <v-sheet
                
                rounded="lg"
                elevation="1"
                style="padding:13px">

                 <v-list-item style="margin-left:-13px">
                     <v-icon color="primary"  >mdi-piggy-bank</v-icon>
                     <h2 class="ml-2">Tokens</h2>
                    </v-list-item>

                <v-card elevation="0" class="mb-5" :loading="tranLoading">

                  <p class="mt-5 ml-3" v-if="allTokenCounts == 0" >No tokens owned .</p>

                  <v-row class="mt-1" >
                    <v-col  class="mt-0" 
                      v-for="item in accountShowTokens"
                      :key="item.key"
                      cols="12"
                      sm="4"
                      md="4"
                      lg="4"
                      xl="2">

                      <v-card elevation="0">

                        <v-list style="background-color:#F2F3F8">
                          
                          <v-list-item>

                            <!-- <v-avatar>
                              <v-img :src="require(`@/assets/tokens/${item.img}.png`)"></v-img>
                            </v-avatar> -->
                           
                            <v-list-item-content class="ml-0">
                             
                              <v-list-item-title>
                                <div style="display:flex;">
                                   <div  style="width:30px">
                                     <!-- <v-img  width="20px" height="20px" :src="require(`@/assets/tokens/${item.img}.png`)"></v-img> -->
                                     <v-img  width="20px" height="20px" :src="item.img"></v-img>
                                   </div>
                                   <div style="width:100%;font-size:14px">  {{item.contract}} </div>
                                </div>
                               
                                </v-list-item-title>
                              <v-list-item-subtitle>
                                <strong>{{item.amount}}</strong> 
                                {{item.name}}
                                </v-list-item-subtitle>
                            </v-list-item-content>

                          </v-list-item>

                        </v-list>

                      </v-card>
                    </v-col>

                    <v-col cols="12" v-if="showAllTokenButton" >
                       <div style="height: 45px;display: flex;justify-content: center;align-items: center;border: 2px solid grey;">
                        <v-btn text @click="showAccountAllToken"> show all {{allTokenCounts}} tokens </v-btn>
                      </div>
                    </v-col>
                    
                  <v-skeleton-loader v-if="false"
                    type="list-item-avatar-three-line">
                  </v-skeleton-loader>

                  </v-row>

                </v-card>
                 
               </v-sheet>

              </v-col>
              
            </v-row>

 
          </v-col>

          <v-col
            cols="12"
            md="2">

            <v-row>

              <v-col>

                <v-sheet
                elevation="1"
                  rounded="lg"
                  min-height="268"
                  style="padding:13px">

                  <v-list-item style="margin-left:-13px">
                     <v-icon color="primary"  >mdi-database</v-icon>
                     <h2 class="ml-2">Buy Resources</h2>
                    </v-list-item>

              <form style="margin:10px">
                <v-text-field
                  :counter="12"
                  label="RAM Receiver"
                  required
                  outlined
                  v-model="ramReceiver"></v-text-field>

                <p>Buy in EOS or Bytes?</p>

                <v-radio-group
                v-model="ramBuyType"
                row
                >
                <v-radio
                  label="EOS"
                  value="BuyRamEOS"
                ></v-radio>
                <v-radio
                  label="Byte"
                  value="BuyRamBytes"
                ></v-radio>
              </v-radio-group>

              <p>{{ramBuyTypeMessage}}</p>

                <v-text-field
                  v-model="ramBuyAmount"
                  :label="ramBuyLabel"
                  required
                  outlined >
                  </v-text-field>
                <p>{{ramWillBuyMessage}}</p>
                 <!-- <p>≈ 0.0090 EOS</p> -->
                
                <v-btn
                  class="mr-4"
                  :disabled="!$store.getters.isAnchorLogined"
                  @click="anchorBuyRam"
                  :loading="isBuyRamLoading"
                  small
                   outlined
                    color="primary"
                >
                  Buy Ram
                </v-btn>

                
                </form>

                </v-sheet>

              </v-col>
        
            </v-row>

            <v-row>

              <v-col>

                <v-sheet
                elevation="1"
                  rounded="lg"
                  min-height="268"
                  style="padding:13px">

                  <v-list-item style="margin-left:-13px">
                     <v-icon color="primary"  >mdi-stack-overflow</v-icon>
                     <h2 class="ml-2">Stake Unstake</h2>
                    </v-list-item>

                <v-radio-group
                  v-model="stakeType"
                  row
                  >
                  <v-radio
                    label="stake"
                    value="stake"
                  ></v-radio>
                  <v-radio
                    label="unstake"
                    value="unstake"
                  ></v-radio>
              </v-radio-group>

              <form style="margin:10px">

                <v-text-field
                  :counter="12"
                  :label ="stakeReceiverLabel"
                  required
                  outlined
                  v-model="stakeReceiver"></v-text-field>

                <v-text-field
                  v-model="stakeCPU"
                  :label ="stakeCPULabel"
                  required
                  outlined></v-text-field>
  
                <v-text-field
                  v-model="stakeNET"
                  :label ="stakeNETLabel"
                  required
                  outlined></v-text-field>
                
                <v-btn
                 outlined
                    color="primary"
                  class="mr-4"
                 :disabled="!($store.getters.isAnchorLogined && isStakable)"
                  @click="anchorStake"
                  :loading="isStakeLoading"
                  small>
                  {{stakeButtonMessage}}
                </v-btn>
                 <!-- :disabled="!$store.getters.isAnchorLogined" -->
                 <!-- <v-btn
                  class="mr-4"
                  :disabled="!$store.getters.isAnchorLogined"
                  @click="anchorStake"
                  :loading="isStakeLoading"
                  small
                >
                  UnStake
                </v-btn> -->
                <!-- 
                  <v-btn
                   fab
                  x-small
                  outlined
                  
                  @click="multiTokenSendClear">
                  <v-icon>mdi-delete</v-icon>
                </v-btn> -->

                
                </form>

                </v-sheet>

              </v-col>
        
            </v-row>

            <v-row>

              <v-col>

                <v-sheet
                  elevation="1"
                  rounded="lg"
                  min-height="268"
                  style="padding:12px"
                  >
                 
                  <v-list-item style="margin-left:-13px">
                     <v-icon color="primary">mdi-vote</v-icon>
                     <h2 class="ml-2">Vote Proxy</h2>
                    </v-list-item>

                  <v-card
                    class="mx-auto mb-5"
                  >
                    <v-img
                      class="white--text align-end"
                        height="200px"
                      src="https://ipfs.io/ipfs/QmT8h5JXHUimArYXjLZNnUyRcNdJdjshR25kMX9z11VeCf"
                    >
                      <v-card-title>maxchoproxy</v-card-title>
                    </v-img>

                    <v-card-subtitle class="pb-0">
                    maxmaxmaxmac
                    </v-card-subtitle>

                    <v-card-text class="text--primary">
                    

                      <div>Doing what's best for EOSIO Community & ecosystem!</div><br>

                      <div style="color:red">APR 1.14 % +REX<br>(This value can be changed)</div>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn
                       small
                       outlined
                        color="primary"
                        @click="anchorVote('maxmaxmaxmac')"
                      >
                        VOTE
                      </v-btn>

                      <!-- <v-btn
                        color="orange"
                        text
                      >
                        Explore
                      </v-btn> -->
                    </v-card-actions>
                  </v-card>


                <v-card v-if="false"
                    class="mx-auto"
                    style="margin-top:10px"
                  >
                    <v-img
                      class="white--text align-end"
                      max-height="200px"
                      :aspect-ratio="16/9"
                      src="https://edenproxy.org/wp-content/uploads/2021/09/Eden-Members-Proxy-Logo.jpg"
                    >
                      <!-- <v-card-title>Rewards - Eden Member's Approved Proxy</v-card-title> -->
                    </v-img>
                    
                    <v-card-subtitle class="pb-0">
                    edenproxyrew
                    </v-card-subtitle>

                    <v-card-text class="text--primary">
                      <div>Established by the EOS Community for the EOS Community</div><br>
                      <div style="color:red">APR 0.71 % +REX<br>(This value can be changed)</div>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn
                        outlined
                        small
                    color="primary"
                        @click="anchorVote('edenproxyrew')"
                      >
                        VOTE
                      </v-btn>

                      <!-- <v-btn
                        color="orange"
                        text
                      >
                        Explore
                      </v-btn> -->
                    </v-card-actions>
                </v-card>


                </v-sheet>

              </v-col>
        
            </v-row>
          

           <v-row>
 
          </v-row>
 
          </v-col>

      </v-row>

     <v-dialog
        v-model="dialog"
        max-width="320">

      <v-card>

        <v-card-title class="text-h6">

          <v-icon
          large
          v-if="isDialogIconSuccess"
          color="green darken-1"
          style="margin-right:5px">
          mdi-checkbox-marked-circle-outline </v-icon>

         <v-icon
          large
          v-else
          color="red darken-1"
          style="margin-right:5px">
          mdi-close-circle </v-icon>
 
          {{dialogTitle}}
        </v-card-title>

        <v-card-text>
         {{dialogMessage}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
 
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
     </v-dialog>
     

     <v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            left
            color="primary"
            @click="toTop"
          >
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>

    </v-container>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
       color="primary"
       right
       top
    >
      Transaction Success

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="openTokenSendTransaction"
        >
          View 
        </v-btn>
      </template>
    </v-snackbar>

<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialogMultiTokenSend"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
     
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialogMultiTokenSend = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>MultiTarget Transfer</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="anchorSendMultiTarget"
              :loading="isMultiTokenSendLoading"
              :disabled="!isVisibleMultiTokenSendTable"
            >
              Transfer
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <!-- <v-subheader>Multi Targets Token Send</v-subheader> -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><strong>Targets</strong></v-list-item-title>
              <v-list-item-subtitle>
                
              <v-radio-group
                v-model="multiTokenSendType"
                row
                >
                <v-radio
                  label="EOS"
                  value="eos"
                ></v-radio>
                <v-radio
                  label="NFT"
                  value="nft"
                ></v-radio>
              </v-radio-group>    

            <v-textarea
            
              v-model="multiTokenSendTarget"
              label="Ctrl+V for MultiTarget"
              outlined
              clearable></v-textarea>
            
            <v-text-field
              v-model="multiTokenSendMemo"
              outlined
              label="Memo(Optional)-replace when there is no memo"
            ></v-text-field>


              <v-tooltip bottom color="warning">
                <template v-slot:activator="{ on, attrs }">
                  <!-- <v-btn class="mr-4"
                         v-bind="attrs"
                         v-on="on"
                         @click="anchorPayPowerUp"  :disabled="(!$store.getters.isAnchorLogined || isPowerUpDisable)"  >PayUp
                  </v-btn> -->

                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    fab
                    outlined
                    x-small
                    @click="multiTokenCtrlV">
                    <v-icon>mdi-check-circle</v-icon>
                  </v-btn>

                </template>
                <span>Check Pasted Text and Convert</span>
              </v-tooltip>

            <!-- <v-btn
              color="primary"
              fab
              outlined
              x-small
              @click="multiTokenCtrlV">
               <v-icon>mdi-check-circle</v-icon>
             </v-btn> -->


            <v-btn
              class="ml-2"
              color="secondary"
              fab
              outlined
              x-small
              @click="multiTokenSendClear">
               <v-icon>mdi-delete</v-icon>
            </v-btn>
 


              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><strong>Target Result</strong></v-list-item-title> 
               
              <v-list-item-subtitle class="mt-4">Total Send Targets Count : 
                <span style="color:#2196F3"> {{multiTokenSendTargetCount}}</span>
                </v-list-item-subtitle>
              <v-list-item-subtitle v-show="multiTokenSendType == 'eos'">Total Send EOS Amount : 
                <span style="color:#2196F3">  {{multiTokenSendTargetEOSAmount}}</span> EOS
               </v-list-item-subtitle>
                <!-- <v-list-item-subtitle v-show="multiTokenSendType != 'eos'">Total Send Assets Count : 
                <span style="color:#2196F3">  {{multiTokenSendTargetAssetCount}}</span>
               </v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <!-- <v-divider></v-divider> -->
        <v-list
          three-line
          subheader
        >
          <!-- <v-subheader>Send Target and Amount</v-subheader> -->
          
        </v-list>

        <template>
          <v-simple-table
            fixed-header
            height="300px"
            v-show="isVisibleMultiTokenSendTable"
          >
            <template v-slot:default>
              <thead>
                <tr >
                  <th class="text-left text-h7" >
                    Account
                  </th>
                  <th class="text-left text-h7">
                    {{getMultiTokenSendTableHeaderName}}
                  </th>
                   <th class="text-left text-h7">
                    Memo
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in multiTokenSendTargetTableData"
                  :key="item.account">
                  <td>{{ item.account }}</td>
                  <td>{{ item.eos }}</td>
                  <td>{{ item.memo }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
      </template>

      </v-card>
    </v-dialog>
  </v-row>
</template>

    </v-main>
 
</template>
 

<script>


import Axios from 'axios';

export default {
  
  name:'tokenSend',
 
  data() {
    return {

      fab: false,

      dialogMultiTokenSend:false,
      multiTokenSendTarget:'',
      multiTokenSendTargetTableData:[],
      multiTokenSendTargetEOSAmount:0,
      multiTokenSendTargetAssetCount:0,
      isMultiTokenSendLoading:false,
      multiTokenSendMemo:'',
      multiTokenSendType:'eos',

      timeZoneDiff:0,
      requestError:'',
      msg:'',
      txID:'',

      dialog: false,
      isPowerUpLoading : false,
      isDialogIconSuccess:false,
      dialogTitle:'',
      dialogMessage:'',
      
      isTokenSendLoading : false,
      ttSendTo:'',
      ttAmount:'',
      ttMemo:'',
      snackMessage:'View Transaction',
      snackbar: false,
      snackbarTimeout:3500,
      powerUpID:'',

      ramReceiver:'',
      ramBuyType:'BuyRamEOS',
      ramBuyTypeMessage:'Amount of RAM to Buy in EOS',
      ramBuyResultMessage:'≈ 1 KB',
      ramPrice:0,
      ramBuyAmount:0,
      ramBuyLabel:'Amount(EOS)',
      ramTotalWillBilled:0,
      isBuyRamLoading:false,

      isStakeLoading : false,
      stakeType:'stake',
      stakeReceiver:'',
      stakeReceiverLabel:'Receiver of Stake',
      stakeCPU:0,
      stakeNET:0,
      stakeCPULabel:'Amount of CPU(in EOS)',
      stakeNETLabel:'Amount of NET(in EOS)',
      stakeButtonMessage:'STAKE',

      isAccountSearchLoading : false,
      searchAccountName :'',
      searchedAccountName:'',
      searchedAccountData:{

        creator:'',
        created:'',
        balance_total:0,
        total_value:0,
        eos_price:'0',
        balance_unstaked:'0',
        balance_refund:'0',
        ram_quota: 0, // 램최대 => 14.05 KB
        ram_usage: 0, //램사용 =>  6.58 KB number2.toFixed(2)
        cpu_self: 0, //self staked
        cpu_selfToOthers:0,
        cpu_total:0, //others stated
        cpu_limit_max: 0, // 최대CPU사용가능량
        cpu_limit_used: 0, // 사용한 CPU 사용 1000이하는 us 1000이상은 ms
        net_limit_max: 0, //1024로 나눠서 1KB 이상은 kb로 처리  19982=> 19.51 KB
        net_limit_used: 0,//1024 아래는 바이트
        net_self: 0, //NET 셀프 staked이오스
        net_selfToOthers:0,
        net_total: 0, // NET tothers staked
        rex_total_balance:0,
        is_proxy:0
      },

      tranItemPerPage:50,
      tranMaxCount:0,
      tranNowPage:1,
      tranLoading:false,
      tranSearchedHistoryData:null,
      transactions:[],
      accountTokens:[],
      accountShowTokens:[],
      showAllTokenButton:false,

       rules: {
          required: value => !!value || 'Required.',
          min: v => v.length > 1 || 'min 2 characters',
          validEOSName: v=> this.isAlreadyUsed && this.isAccountValidShow || 'not valid account name',
          onlyEnlglishAndNumber: v =>  /^[a-z1-5.]{2,12}$/.test(v) || 'only english and 1-5 number characters',
        },

      isAlreadyUsed:false,
      isAccountValidShow:false,
      isAccoundNameValid:false,
      allTokenCounts:0

    }
  },
  watch: {
  '$store.state.mainAnchorLoginName': function() {
      //console.log("loginNameChanged:" + this.$store.state.mainAnchorLoginName)
      if(this.$store.state.mainAnchorLoginName != "")
      {
        this.getAccountInfo(this.$store.state.mainAnchorLoginName);
        this.ramReceiver = this.$store.state.mainAnchorLoginName;
      }
    },
    ramBuyType : function(val){

      if(val == 'BuyRamEOS')
      {
        //이오스로 구매
        this.ramBuyTypeMessage = 'Amount of RAM to Buy in EOS';
        this.ramBuyAmount = this.ramPrice;
        this.ramBuyLabel = 'Amount(EOS)';
        //this.ramBuyResultMessage = '≈ 1024 Bytes';
      }
      else{
        //램으로 구매
        this.ramBuyTypeMessage = 'Amount of RAM to Buy in Bytes';
        this.ramBuyAmount = '1024';
        this.ramBuyLabel = 'Amount(Bytes)';
        //this.ramBuyResultMessage = '≈ ' + this.ramPrice + ' EOS';
      }

    },
    stakeType : function(val){

      if(val == 'stake')
      {
        //stake
        this.stakeCPULabel = 'Amount of CPU to Stake(in EOS)';
        this.stakeNETLabel = 'Amount of NET to Stake(in EOS)';
        this.stakeReceiverLabel = 'Receiver of Stake';
        this.stakeButtonMessage = 'STAKE';
        
      }
      else{
        //unstake
        this.stakeCPULabel = 'Amount of CPU to Unstake(in EOS)';
        this.stakeNETLabel = 'Amount of NET to Unstake(in EOS)';
        this.stakeReceiverLabel = 'Account to Unstake';
        this.stakeButtonMessage = 'UNSTAKE';
      }

    }

  },
  computed: {

      isResRowVisible(){

      },

      ramWillBuyMessage(){

        if(this.ramBuyType == 'BuyRamEOS')
        {
          this.ramTotalWillBilled = this.ramBuyAmount;
          var temp = ((this.ramBuyAmount/this.ramPrice) * 1024);
          return  '≈ ' + Math.round(temp) +' Bytes';
        }
        else{
          
          var temp = ((this.ramBuyAmount/1024) * this.ramPrice);
          this.ramTotalWillBilled = temp.toFixed(4);
          return '≈ ' + temp.toFixed(4) + ' EOS';
        }

      },
      getMultiTokenSendTableHeaderName(){

        return this.multiTokenSendType =='eos'? "EOS":"ASSET";

      },
      isVisibleMultiTokenSendTable(){
        return this.multiTokenSendTargetTableData.length == 0 ? false:true;  
      },
      multiTokenSendTargetCount(){
        return this.multiTokenSendTargetTableData.length;
      },
      transactionLink(){
          return 'https://bloks.io/transaction/' + this.txID;
      },
      transactionSuccess(){
          return this.txID=="" ? false:true
      },
      isNexPageEnable(){
        return this.tranNowPage * this.tranItemPerPage > this.tranMaxCount;
      },
      isPrePageEnable()
      {
        return this.tranNowPage==1;
      },
      getPageRange()
      {
        if(this.tranMaxCount == 0) return "0 - 0";

        if(this.tranNowPage * this.tranItemPerPage > this.tranMaxCount)
        {
          return (this.tranItemPerPage*(this.tranNowPage-1) +1) + " - " + this.tranMaxCount;
        }
        else
        {
          return (this.tranItemPerPage*(this.tranNowPage-1) +1) + " - " + this.tranItemPerPage*(this.tranNowPage);
        }
 

      },
      isPowerUpDisable(){
          return this.powerUpID.length <= 1 ? true:false
      },
      accountInfoCreatedDate()
      {
        var date = new Date(this.searchedAccountData.created);
        //console.log(this.searchedAccountData.created);

        // return date.getFullYear()+". "+(date.getMonth()+1)+". "+date.getDate()+ ". "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
        return date.getFullYear()+". "+(date.getMonth()+1)+". "+date.getDate();
      },
      accountInfoOtherStaked()
      {
        // var cpu = this.searchedAccountData.cpu_total - this.searchedAccountData.cpu_self- this.searchedAccountData.cpu_selfToOthers;
        // var net = this.searchedAccountData.net_total - this.searchedAccountData.net_self- this.searchedAccountData.net_selfToOthers;

        var cpu = this.searchedAccountData.cpu_total - this.searchedAccountData.cpu_self;
        var net = this.searchedAccountData.net_total - this.searchedAccountData.net_self;

        return parseFloat(cpu + net).toFixed(4);
      },
      accountInfoOtherStakedOnlyCPU()
      {
        // return parseFloat(this.searchedAccountData.cpu_total - this.searchedAccountData.cpu_self- this.searchedAccountData.cpu_selfToOthers).toFixed(4);
        return parseFloat(this.searchedAccountData.cpu_total - this.searchedAccountData.cpu_self).toFixed(4);
      },
       accountInfoOtherStakedOnlyNET()
      {
        // return parseFloat(this.searchedAccountData.net_total - this.searchedAccountData.net_self- this.searchedAccountData.net_selfToOthers).toFixed(4);
        return parseFloat(this.searchedAccountData.net_total - this.searchedAccountData.net_self).toFixed(4);
      },
       accountInfoRamPercent()
      {
        return Math.round((this.searchedAccountData.ram_usage / this.searchedAccountData.ram_quota) * 100);;
      },
       accountInfoCPUPercent()
      {
        if(this.searchedAccountData.cpu_limit_used > this.searchedAccountData.cpu_limit_max) return 100;
        return Math.round((this.searchedAccountData.cpu_limit_used / this.searchedAccountData.cpu_limit_max) * 100);
      },
       accountInfoNETPercent()
      {
        if(this.searchedAccountData.net_limit_used > this.searchedAccountData.net_limit_max) return 100;
        return  Math.round((this.searchedAccountData.net_limit_used / this.searchedAccountData.net_limit_max) * 100);
      },
      getDan_ram_usage()
      {
        
        var used;
        var max;

        if(this.searchedAccountData.ram_quota > 1024)
        {
            max = (this.searchedAccountData.ram_quota / 1024).toFixed(2) + " KB";
        }
        else{
            max = this.searchedAccountData.ram_quota  + " Bytes";
        }

        if(this.searchedAccountData.ram_usage > 1024)
        {
            used = (this.searchedAccountData.ram_usage / 1024).toFixed(2) + " KB";
        }
        else{
            used = this.searchedAccountData.ram_usage  + " Bytes";
        }

        return used + ' / ' + max;
        
      },
      getDan_cpu_usage()
      {
        //0 µs / 3.03 ms
        var used;
        var max;

        if(this.searchedAccountData.cpu_limit_max > 1024)
        {
            max = (this.searchedAccountData.cpu_limit_max / 1000).toFixed(2) + " ms";
        }
        else{
            max = this.searchedAccountData.cpu_limit_max  + " µs";
        }

        if(this.searchedAccountData.cpu_limit_used > 1024)
        {
            used = (this.searchedAccountData.cpu_limit_used / 1000).toFixed(2) + " ms";
        }
        else{
            used = this.searchedAccountData.cpu_limit_used  + " µs";
        }

        return used + ' / ' + max;
      },
      getDan_net_usage()
      {
        //Bytes / 19.51 KB
        var used;
        var max;

        if(this.searchedAccountData.net_limit_max > 1024)
        {
            max = (this.searchedAccountData.net_limit_max / 1024).toFixed(2) + " KB";
        }
        else{
            max = this.searchedAccountData.net_limit_max  + " Bytes";
        }

        if(this.searchedAccountData.net_limit_used > 1024)
        {
            used = (this.searchedAccountData.net_limit_used / 1024).toFixed(2) + " KB";
        }
        else{
            used = this.searchedAccountData.net_limit_used  + " Bytes";
        }

        return used + ' / ' + max;
      },
      isTokenSendable(){
          
       if(this.ttSendTo=='' || this.ttAmount =='')
       {
         return false;
       } 
       else{

        if(this.ttSendTo.length > 12) return false;

        return true;

       }                 

      },
       isStakable(){
          
       if(this.stakeReceiver == '')
       {
         return false;
       } 
       else if(this.stakeCPU == '0' && this.stakeNET == '0')
       {
         return false;
       }
       else{

        if(this.stakeReceiver.length > 12) return false;
        if(this.stakeReceiver.length < 2) return false;

        return true;

       }                 

      },


      //  ttSendToErrors () {
      //   const errors = []
      //   if (!this.$v.name.$dirty) return errors
      //   !this.$v.name.maxLength && errors.push('Name must be at most 10 characters long')
      //   !this.$v.name.required && errors.push('Name is required.')
      //   return errors
      // },
      // ttAmountErrors () {
      //   const errors = []
      //   if (!this.$v.email.$dirty) return errors
      //   !this.$v.email.email && errors.push('Must be valid e-mail')
      //   !this.$v.email.required && errors.push('E-mail is required')
      //   return errors
      // },
  },
  methods: {
 
   async afterAccountNameChanged()
    {

      if(this.ttSendTo.length < 2 )
      {
         this.isAccoundNameValid = false
         this.isAccountValidShow = false
         return
      }

      if( /^[a-z1-5.]{2,12}$/.test(this.ttSendTo) == false) 
      {
        this.isAccoundNameValid = false
        this.isAccountValidShow = false
        return
      }

      this.isAccoundNameValid = true
      this.isAccountValidShow = true
      this.isAlreadyUsed = await this.checkAccountOccupied()

     
    },

    async checkAccountOccupied()
    {
      try {

         var result =  await this.$eosjsRPC.get_account(this.ttSendTo)

        if(result != null)
        {
           return true
        }
        
      } catch (error) {
        return false
      }
      
    },


     multiTokenCtrlV(){

      var parcedData = this.multiTokenSendTarget.split('\n');

      this.multiTokenSendTargetTableData = [];
      this.multiTokenSendTargetEOSAmount = 0;
      var tempEosAmount = 0.0;

      parcedData.map((value, index) => {
       
        if(value == '') return false;
        
        var accountAndEos = value.split('\t');
        tempEosAmount += parseFloat(accountAndEos[1]);

        if(accountAndEos.length == 2)
        {
          this.multiTokenSendTargetTableData.push(
          {
            account:accountAndEos[0],
            eos:accountAndEos[1],
            memo:this.multiTokenSendMemo
          });
        }
        else if(accountAndEos.length == 3){
          this.multiTokenSendTargetTableData.push(
          {
            account:accountAndEos[0],
            eos:accountAndEos[1],
            memo:accountAndEos[2]
          });
        }
        else{
          return false;
        }
        
      });
      
      //this.multiTokenSendTargetCount = this.multiTokenSendTargetTableData.length;
      this.multiTokenSendTargetEOSAmount = tempEosAmount.toFixed(4);
     

    },

    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    },

    anchorStake(){

      this.isStakeLoading = true; 

      var action;

        if(this.stakeType == 'stake')
        {
          //스테이크
          action = {
            account: 'eosio',
            name: 'delegatebw',
            authorization: [this.$store.state.mainAnchorSession.auth],
            data: {
                from: '............1',
                receiver: this.stakeReceiver,
                stake_cpu_quantity: parseFloat(this.stakeCPU).toFixed(4) + ' EOS',
                stake_net_quantity: parseFloat(this.stakeNET).toFixed(4) + ' EOS',
                transfer:false
            }
          }

        }
        else{
          
          //언스테이크
          action = {
            account: 'eosio',
            name: 'undelegatebw',
            authorization: [this.$store.state.mainAnchorSession.auth],
            data: {
                from: '............1',
                receiver: this.stakeReceiver,
                unstake_cpu_quantity: parseFloat(this.stakeCPU).toFixed(4) + ' EOS',
                unstake_net_quantity: parseFloat(this.stakeNET).toFixed(4) + ' EOS',
            }
          }
           
        }
 
       
       this.$store.state.mainAnchorSession.transact({action}).then((result) => 
       {
         this.txID = result.processed.id;
         
       })
       .catch((error) => {
        alert(error);
       })
      .finally(()=> { 
        this.isStakeLoading = false; 
         
        });
 
    },

    anchorBuyRam(){

      this.isBuyRamLoading = true; 

   
      var action;

        if(this.ramBuyType == 'BuyRamEOS')
        {
          //이오스로 구매시
          action = {
            account: 'eosio',
            name: 'buyram',
            authorization: [this.$store.state.mainAnchorSession.auth],
            data: {
                payer: '............1',
                receiver: this.ramReceiver,
                quant: parseFloat(this.ramBuyAmount).toFixed(4) + ' EOS',
            }
          }

        }
        else{
          
          //램바이트로 구매시
          action = {
            account: 'eosio',
            name: 'buyrambytes',
            authorization: [this.$store.state.mainAnchorSession.auth],
            data: {
                payer: '............1',
                receiver: this.ramReceiver,
                bytes: this.ramBuyAmount,
            }
          }
           
        }
 
       
       this.$store.state.mainAnchorSession.transact({action}).then((result) => 
       {
         this.txID = result.processed.id;
         
       })
       .catch((error) => {
        //this.requestError = error
        alert(error);
       })
      .finally(()=> { 
        this.isBuyRamLoading = false; 
        
        //this.getVioletGardenVIOInfoWithAjax();
        
        });
 
    },
    showDialogMultiTokenSend(){
      this.multiTokenSendTarget = '';
      this.dialogMultiTokenSend = true;
      this.multiTokenSendTargetTableData = [];
      this.multiTokenSendTargetEOSAmount = 0;
      this.multiTokenSendTargetAssetCount = 0;
      this.multiTokenSendMemo = '';
      this.multiTokenSendType='eos';
    },
    multiTokenSendClear()
    {
      this.multiTokenSendTarget = '';
      this.multiTokenSendTargetTableData = [];
      this.multiTokenSendTargetEOSAmount = 0;
      this.multiTokenSendTargetAssetCount = 0;
      this.multiTokenSendMemo = '';
    },
    anchorSendMultiTarget(){
 
      var regex = /[^0-9.]/g;				// 숫자가 아닌 문자열을 선택하는 정규식
      
      var actions = [];
      var onlyNumberAmount;
      var sendAmount;

      //return this.multiTokenSendType =='eos'? "EOS":"ASSET";
      if(this.multiTokenSendType =='eos')
      {
          this.multiTokenSendTargetTableData.map((value, index) => {

          onlyNumberAmount = value.eos.replace(regex, "");	
          sendAmount = parseFloat(onlyNumberAmount).toFixed(4);

          actions.push(
            {
                account: 'eosio.token',
                name: 'transfer',
                authorization: [this.$store.state.mainAnchorSession.auth],
                data: {
                  from: '............1',
                  to: value.account,
                  quantity: sendAmount + ' EOS',
                  memo: value.memo
                }
          });
        
        });
      }
      else{

        var tempAssets ;
        var tempParcedAssets;

        this.multiTokenSendTargetTableData.map((value, index) => {

          tempAssets = []
          tempParcedAssets = value.eos.split(',');

          tempParcedAssets.forEach( (value,key) => {
            tempAssets.push(value);
          });
          
          actions.push(
            {
                account: 'atomicassets',
                name: 'transfer',
                authorization: [this.$store.state.mainAnchorSession.auth],
                data: {
                  from: '............1',
                  to: value.account,
                  asset_ids: tempAssets,
                  memo: value.memo
                }
          });
        
        });

      }
      
      this.isMultiTokenSendLoading = true;

       //세션으로 요청해도 성공
       this.$store.state.mainAnchorSession.transact({actions}).then((result) => 
       {
        this.txID = result.processed.id;
        this.snackbar = true;
       })
       .catch((error) => {
        this.requestError = error
        alert(this.requestError);
       })
      .finally(()=> { this.isMultiTokenSendLoading = false;  });

    },
 
    anchorSendToken(){
        
      var regex = /[^0-9.]/g;				// 숫자가 아닌 문자열을 선택하는 정규식
      var onlyNumberAmount = this.ttAmount.replace(regex, "");	
      var sendAmount = parseFloat(onlyNumberAmount).toFixed(4);
 
      if(sendAmount == '' || sendAmount == '0.0000') return;
      // regex = /[^a-z1-5.]/g; 
      // var onlyNumberAmount = this.ttAmount.replace(regex, "");	
      // var sendAmount = parseFloat(onlyNumberAmount).toFixed(4);
 
      // if(sendAmount == '') return;


      this.isTokenSendLoading = true;

      const action = {
                account: 'eosio.token',
                name: 'transfer',
                authorization: [this.$store.state.mainAnchorSession.auth],
                data: {
                    from: this.$store.state.mainAnchorSession.auth.actor,
                    to: this.ttSendTo,
                    quantity: sendAmount + ' EOS',
                    memo: this.ttMemo
                }
            }
            

       //세션으로 요청해도 성공
       this.$store.state.mainAnchorSession.transact({action}).then((result) => 
       {
        this.txID = result.processed.id;
        this.snackbar = true;
       })
       .catch((error) => {
        this.requestError = error
        alert(this.requestError);
       })
      .finally(()=> { this.isTokenSendLoading = false;  });
 
    },
    anchorVote(proxyName){

      const action = {
            account: 'eosio',
            name: 'voteproducer',
            authorization: [this.$store.state.mainAnchorSession.auth],
            data: {
                voter: '............1',
                proxy: proxyName,
                producers: [],
            }
      }
       
       this.$store.state.mainAnchorSession.transact({action}).then((result) => 
       {
         this.txID = result.processed.id;
         //alert(this.txID);
       })
       .catch((error) => {
        //this.requestError = error
        alert(error);
       })
      .finally(()=> { 
        //this.isClaimVGLoading = false; 
        
        //this.getVioletGardenVIOInfoWithAjax();
        
        });
 
    },

    anchorPayPowerUp(){

      const action = {
            account: 'eosio',
            name: 'powerup',
            authorization: [this.$store.state.mainAnchorSession.auth],
            data: {
                cpu_frac: 88282980,
                days: 1,
                max_payment:'0.0027 EOS',
                net_frac:111460,
                payer:'............1',
                receiver:this.powerUpID
            }
      }

      
      // const action = {
      //       account: 'eosio',
      //       name: 'powerup',
      //       authorization: [this.$store.state.mainAnchorSession.auth],
      //       data: {
      //           cpu_frac: 0,
      //           days: 1,
      //           max_payment:'0.0027 EOS',
      //           net_frac:1114600,
      //           payer:'............1',
      //           receiver:this.powerUpID
      //       }
      // }
       
       this.$store.state.mainAnchorSession.transact({action}).then((result) => 
       {
         this.txID = result.processed.id;
         //alert(this.txID);
       })
       .catch((error) => {
        //this.requestError = error
        alert(error);
       })
      .finally(()=> { 
        //this.isClaimVGLoading = false; 
        
        //this.getVioletGardenVIOInfoWithAjax();
        
        });
 
    },

    anchorPayPowerUpTEST(){

      const action = {
            account: 'eosio',
            name: 'powerup',
            authorization: [this.$store.state.mainAnchorSession.auth],
            data: {
                cpu_frac: 88282980,
                days: 1,
                max_payment:'0.0027 EOS',
                net_frac:111460,
                payer:'............1',
                receiver:this.powerUpID
            }
      }
       
       this.$store.state.mainAnchorSession.transact({action}).then((result) => 
       {
         this.txID = result.processed.id;
         //alert(this.txID);
       })
       .catch((error) => {
        //this.requestError = error
        alert(error);
       })
      .finally(()=> { 
        //this.isClaimVGLoading = false; 
        
        //this.getVioletGardenVIOInfoWithAjax();
        
        });
 
    },



    clear () {
        this.ttSendTo = ''
        this.ttAmount = ''
        this.ttMemo = ''
      },
      powerUp(){
        this.powerUpRaw(this.powerUpID);
      },
      powerUpWithAccount(){
        this.powerUpID = this.$store.state.mainAnchorLoginName;
      },
      powerUpRaw(accountName)
      {
        if(accountName == '') return;

        this.isPowerUpLoading = true;
        Axios.get('https://api.eospowerup.io/freePowerup/' + accountName)
             .then(res => 
             { 
               if(res.data.result.status == "success")
               {
                  
                  this.isDialogIconSuccess = true;
                  this.dialogTitle = "PowerUp Success";
                  this.dialogMessage = res.data.result.powerupLog.message;
               }
               else
               {

                  console.log(res.data)
                  
                  this.dialog = true;
                  this.isDialogIconSuccess = false;
                  this.dialogTitle = "PowerUp Fail";
                  this.dialogMessage = res.data.result.powerupLog.message;
               }

               this.dialog = true;
              
             })
             .catch(err => 
             { 
                this.isDialogIconSuccess = false;
                this.dialogTitle = "PowerUp Fail";
                this.dialogMessage = err;
                this.dialog = true;
             })
             .finally(()=> { this.isPowerUpLoading = false;  });
      },
      openTokenSendTransaction()
      {
        this.snackbar = false;
        var url = 'https://bloks.io/transaction/' + this.txID;
        window.open(url, '_blank');
      },
      getAccountInfo_EOSFlare(_accountName)
      {
        this.isAccountSearchLoading = true;
 
        Axios.post('https://api.eosflare.io/v1/eosflare/get_account', {account: _accountName})
        .then(res=> {
         
          if(res.data.err_code != 0)
          {
            this.isDialogIconSuccess = false;
            this.dialogTitle = "AccountInfo Load Fail";
            this.dialogMessage = res.data.err_msg;
            this.dialog = true;
            return;
          }

          this.searchedAccountName = _accountName;

        // creator:'leckoaccount',
        // created:'1534343937000',
        // balance_total:'0.000',
        // total_value:'',
        // eos_price:'',
        // balance_unstaked:'',
        // balance_refund:'',
        // ram_quota: 14386, // 램최대 => 14.05 KB
        // ram_usage: 6736, //램사용 =>  6.58 KB number2.toFixed(2)
        
        // cpu_self: 0.0002, //self staked
        // cpu_total: 3370.7866, //others stated
        // cpu_limit_max: 3034, // 최대CPU사용가능량
        // cpu_limit_used: 443, // 사용한 CPU 사용 1000이하는 us 1000이상은 ms
        // net_limit_max: 19982, //1024로 나눠서 1KB 이상은 kb로 처리  19982=> 19.51 KB
        // net_limit_used: 273,//1024 아래는 바이트
        // net_self: 0, //NET 셀프 staked이오스
        // net_total: 1.0639, // NET tothers staked
        // rex_total_balance:'',

        
 
         var accountData = res.data.account;
         //console.log(accountData);



         this.searchedAccountData.creator = _accountName == "eosio" ? "self": accountData.creator;
         this.searchedAccountData.created = accountData.created;

         // console.log( accountData.created);

         this.searchedAccountData.balance_total = accountData.balance_total;
         this.searchedAccountData.total_value = accountData.total_value;
         this.searchedAccountData.eos_price = accountData.eos_price;
         this.searchedAccountData.balance_unstaked = accountData.balance_unstaked;
         this.searchedAccountData.balance_refund = accountData.balance_refund;

         this.searchedAccountData.ram_quota = accountData.ram_quota;
         this.searchedAccountData.ram_usage = accountData.ram_usage;

         //this.searchedAccountData.cpu_self = accountData.cpu_self;
         this.searchedAccountData.cpu_total = accountData.cpu_total;
         this.searchedAccountData.cpu_limit_max = accountData.cpu_limit_max;
         this.searchedAccountData.cpu_limit_used = accountData.cpu_limit_used;
         this.searchedAccountData.net_limit_max = accountData.net_limit_max;
         this.searchedAccountData.net_limit_used = accountData.net_limit_used;
         //this.searchedAccountData.net_self = accountData.net_self;
         this.searchedAccountData.net_total = accountData.net_total;
         this.searchedAccountData.rex_total_balance = accountData.rex_total_balance;
         this.searchedAccountData.is_proxy = accountData.is_proxy;

         //staked에서 값을 모두 읽는다.
         //1.자신과 같은아이디면 self로 처리
         //2.자신과 같지 않으면 cpu_staked to others로 처리 하며 cpu staked에도 추가한다.
         //3.위의 값을 net_total과 cpu_total에서 뺀값은 Staked from others로 처리된다.
         var staked = accountData.staked;

         var cpu_self =0;
         var cpu_selfToOthers=0;
         var net_self=0;
         var net_selfToOthers=0;

         staked.forEach( (value,key) => {
            
            if(value.to == this.searchedAccountName)
            {
              cpu_self += value.cpu;
              net_self += value.net;
            }
            else{
              cpu_selfToOthers += value.cpu;
              net_selfToOthers += value.net;
            }


         });

         this.searchedAccountData.cpu_self = cpu_self;
         this.searchedAccountData.net_self = net_self;
         this.searchedAccountData.cpu_selfToOthers = cpu_selfToOthers;
         this.searchedAccountData.net_selfToOthers = net_selfToOthers;


         this.ramPrice = accountData.ram_price;


         if(this.ramBuyType == 'BuyRamEOS') this.ramBuyAmount = this.ramPrice;

         //histroy load
         this.tranNowPage = 1;
         this.getHistory();
       

        })
        .catch(err => {
          this.isDialogIconSuccess = false;
          this.dialogTitle = "AccountInfo Load Fail";
          this.dialogMessage = err;
          this.dialog = true;
        })
        .finally(()=> { this.isAccountSearchLoading = false;  });
      },
      tranLeft(){
         this.tranNowPage--;
         this.getHistory();
         //this.tranLoading = false;

      },
      tranRight(){
         this.tranNowPage++;
         this.getHistory();
         //this.tranLoading = true;

         //1.eosio.token만 Send Receive로 처리하고 나머지는 ContractName - ActionName으로 처리한다.
         //2.토큰 전송만 수신은 한줄로 메모처리한다.
         //3.2번을 제외하고는 Data를 정보창에 넣어준다.
      },
      getHistory()
      {
         
        this.tranLoading = true;
        if (this.searchedAccountName == "") return;

        var nowPos = -(this.tranItemPerPage * this.tranNowPage + 1);
 
        //Axios.defaults.withCredentials = true;
        // Axios.post('https://api.eosflare.io/v1/eosflare/get_actions', {account_name: this.searchedAccountName,pos:nowPos,offset:this.tranItemPerPage})
        Axios.post('https://cors-anywhere.herokuapp.com/https://api.eosflare.io/v1/eosflare/get_actions', {account_name: this.searchedAccountName,pos:nowPos,offset:this.tranItemPerPage})
        .then(res=> {

          //console.log(res);
          //return;
        
          if(res.status != 200)
          {
            this.isDialogIconSuccess = false;
            this.dialogTitle = "History Load Fail";
            this.dialogMessage = res.statusText;
            this.dialog = true;
            return;
          }

        

          this.tranSearchedHistoryData = res.data.actions;
        
          this.convertHistroyToTransaction();
 

        })
        .catch(err => {
          this.isDialogIconSuccess = false;
          this.dialogTitle = "History Load Fail";
          this.dialogMessage = err;
          this.dialog = true;
        })
        .finally(()=> { this.tranLoading = false;  });
      },
      convertHistroyToTransaction()
      {

          // transactions: [
          // {
          //   tx: 'c0fcdde4',
          //   date: 'Dec 20, 2021 01:32:06 AM',
          //   action:'Receive Token',
          //   data:'kordeveloper    →   shallwedance   0.0001 EOS test on landingsite'
          // },

         //이전 로드된 데이터 삭제
         this.transactions.length = 0;
         // 배열 거꾸로
         const reverse = [...this.tranSearchedHistoryData].reverse();

         //historyMaxCount설정
         if(this.tranNowPage == 1)
         {
            if(reverse.length ==0)
            {
              this.tranMaxCount = 0;
            }
            else
            {
              this.tranMaxCount = reverse[0].account_action_seq;
            }
         }
         
        
         var actionTitle, actionMessage , actionMemo,tokenQuantity;
         var actionData,actionAccountName,actionName;
         var tempActionType;
         var seq= 0;
         reverse.map(item=>{

            //console.log(item);

            actionData = item.action_trace.act.data;
            actionAccountName=item.action_trace.act.account;
            actionName=item.action_trace.act.name;

            if(actionAccountName == "eosio.token" && actionName == "transfer")
            {
               if(actionData.to == this.searchedAccountName)
               {
                 //수신
                 tempActionType = 1;
                 actionTitle = "Receive Token";
               }
               else
               {
                 //전송
                 tempActionType = 0;
                 actionTitle = "Send Token";
               }
 
                actionMessage = actionData.from + " -> " + actionData.to;
              
                actionMemo = actionData.memo;

                var parcedToken = actionData.quantity.split(' ');
                tokenQuantity = parseFloat(parcedToken[0]) + " " + parcedToken[1];
            }
            else if(actionAccountName == "eosio")
            {

               actionMemo = '';
               tokenQuantity = '';
               actionMessage = actionData;
               tempActionType = 3;

               switch (actionName) {
                 case 'linkauth':
                  actionTitle = "Link Auth";
                  break;
                 case 'unlinkauth':
                   actionTitle = "Unlink Auth";
                   break;
                 case 'updateauth':
                   actionTitle = "Update Auth";
                   break;
                 case 'deleteauth':
                   actionTitle = "Delete Auth";
                   break;
                 case 'buyrambytes':
                   actionTitle = "Buy RAM";
                   break;
                 case 'buyram':
                   actionTitle = "Buy RAM";
                   break;
                 case 'sellram':
                   actionTitle = "Sell RAM";
                   break;
                 case 'newaccount':
                   actionTitle = "New Account";
                   break;
                 case 'delegatebw':
                   actionTitle = "Stake CPU/NET";
                   tempActionType = 4;
                   break;
                 case 'undelegatebw':
                   actionTitle = "Unstake CPU/NET";
                   tempActionType = 4;
                   break;
                 default:
                   //일반 컨트랙처럼 처리
                   tempActionType = 2;
                   actionTitle =  actionAccountName +'-'+actionName;
                   break;
               }
                
            }
            else if(actionAccountName == "atomicassets" && actionName == "transfer")
            {
               tempActionType = 2;
               actionTitle =  actionAccountName +'-'+actionName;
               actionMessage = actionData;
               actionMemo = '';
               tokenQuantity = '';
            }
            else
            {
 
               tempActionType = 2;
               actionTitle =  actionAccountName +'-'+actionName;

               if(actionName == "transfer")
               {
                  actionMessage = actionData.from + " -> " + actionData.to;
                  var parcedToken = actionData.quantity.split(' ');
                  tokenQuantity = parseFloat(parcedToken[0]) + " " + parcedToken[1];
                  actionMemo = actionData.memo;
               }
               else
               {
                  actionMessage = actionData;
                  actionMemo = '';
                  tokenQuantity = '';
               }
            
            }
 
            this.transactions.push(

                {
                  seq:seq ++,
                  tx: item.action_trace.trx_id.substring(0,8).toLowerCase(), //8자리만 파싱해야함
                  txFull:item.action_trace.trx_id,
                  date: item.block_time, //변환필요
                  actionAccountName:item.action_trace.act.account,
                  actionName:item.action_trace.act.name,
                  actionType:tempActionType,//0:토큰전송,1:토큰수신,3:Data(EOSIO 토큰전송이외의것처리),4:stake/unstake
                  action:actionTitle,
                  tokenQuantity:tokenQuantity, //EOS일 경우만 사용
                  actionMemo:actionMemo, //
                  data:actionMessage,
                  from:item.action_trace.act.data.from,
                  to:item.action_trace.act.data.to,
                  irreversible:item.irreversible
                },

            )



         });

         //console.log(this.transactions);

          // this.tranSearchedHistoryData.forEach((value, index) => 
          // {
           
          //   console.log(value);
          //   console.log(index);

          //   this.transactions.push(

          //       {
          //         tx: 'c0fcdde4',
          //         date: 'Dec 20, 2021 01:32:06 AM',
          //         action:'Receive Token',
          //         data:'kordeveloper    →   shallwedance   0.0001 EOS test on landingsite'
          //       },

          //   )
          // });     
      },
      getTimezoneDate(date)
      {
        //console.log(date);
        var cDate =new Date(date);
        //console.log(cDate);
        //cDate.setHours(cDate.getHours() + this.timeZoneDiff);
        return cDate.getFullYear()+"-"+(cDate.getMonth()+1)+"-"+cDate.getDate()+ " "+cDate.getHours()+":"+cDate.getMinutes()+":"+cDate.getSeconds();
      },
      //  accountInfoCreatedDate()
      // {
      //   var date = new Date(this.searchedAccountData.created);
      //   //console.log(this.searchedAccountData.created);

      //   return date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()+ " "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
      // },

   async getRamPrice()
    {
      
        var sendJson = {"table":"rammarket","scope":"eosio","code":"eosio","limit":10,"json":true}

        var result =  await this.$eosjsRPC.get_table_rows(sendJson)

        if( result.rows.length == 1)
        {
            const quoteBalance = parseFloat(result.rows[0].quote.balance)
            const baseBalance = parseFloat(result.rows[0].base.balance)
            const ramPrice = quoteBalance / baseBalance * 1024
          
            this.ramPrice =  Number(ramPrice.toFixed(4))  
            //return ramPrice
        }

    },

      async getAccountInfo(_accountName)
      {

        this.isAccountSearchLoading = true;

        var tempResult =  await this.$eosjsRPC.get_account(_accountName)
        this.searchedAccountName = _accountName;

         var regex = /[^0-9.]/g;	
         var accountData = tempResult;

        this.searchedAccountData.creator = "creator"
        this.searchedAccountData.created = accountData.created;

        //스테이킹한것까지 포함해야함
        var tempTotalBalance = 0.0000;

        if( accountData.core_liquid_balance != null){
             tempTotalBalance += parseFloat(accountData.core_liquid_balance.replace(regex, ""))	
        }
       

        this.searchedAccountData.balance_unstaked = accountData.core_liquid_balance;

        if( accountData.refund_request != null){
           
           			// 숫자가 아닌 문자열을 선택하는 정규식
            var cpu = accountData.refund_request.cpu_amount.replace(regex, "");	
            var net = accountData.refund_request.net_amount.replace(regex, "");	

            this.searchedAccountData.balance_refund = ( parseFloat(cpu) +  parseFloat(net)).toFixed(4);

            tempTotalBalance+=  parseFloat(cpu) +  parseFloat(net)
        }
        else{
            this.searchedAccountData.balance_refund = "0.0000";
        }

        
         this.searchedAccountData.ram_quota = accountData.ram_quota;
         this.searchedAccountData.ram_usage = accountData.ram_usage;

         //전체 스테이킹된 EOS 갯수
         this.searchedAccountData.cpu_total = parseFloat(accountData.total_resources.cpu_weight.replace(regex, ""))

         this.searchedAccountData.cpu_limit_max = accountData.cpu_limit.max;
         this.searchedAccountData.cpu_limit_used = accountData.cpu_limit.used;

         this.searchedAccountData.net_total = parseFloat(accountData.total_resources.net_weight.replace(regex, ""))

         this.searchedAccountData.net_limit_max = accountData.net_limit.max;
         this.searchedAccountData.net_limit_used = accountData.net_limit.used;

         //this.searchedAccountData.net_self = accountData.net_self;
        
         if(accountData.rex_info != null)
         {
            this.searchedAccountData.rex_total_balance = accountData.rex_info.rex_balance
         }
         else{
          this.searchedAccountData.rex_total_balance = '0.0000 REX'
         }

         
          if(accountData.voter_info != null)
          {
            this.searchedAccountData.is_proxy = accountData.voter_info.is_proxy
          }
        

        if(accountData.self_delegated_bandwidth != null)
        {
          this.searchedAccountData.cpu_self = parseFloat(accountData.self_delegated_bandwidth.cpu_weight.replace(regex, ""))
          this.searchedAccountData.net_self = parseFloat(accountData.self_delegated_bandwidth.net_weight.replace(regex, ""))

          tempTotalBalance += (this.searchedAccountData.cpu_self +  this.searchedAccountData.net_self)
        }
        else{

           this.searchedAccountData.cpu_self = 0.0000
           this.searchedAccountData.net_self = 0.0000

        }
      
         this.searchedAccountData.cpu_selfToOthers = 0.0000
         this.searchedAccountData.net_selfToOthers = 0.0000
         

         if(this.ramBuyType == 'BuyRamEOS') this.ramBuyAmount = this.ramPrice;


         // 리퀴드 + 리펀딩 + cpu and net self staked
         //console.log(tempTotalBalance.toFixed(4))
         this.searchedAccountData.balance_total = tempTotalBalance.toFixed(4);

        this.isAccountSearchLoading = false; 

        this.getAccountTokenList(_accountName);
 

      },
      getAccountTokenList(accountName)
      {

        this.tranLoading = true
        this.allTokenCounts = 0
        this.accountShowTokens = []

        if(accountName == '') return;
 
        Axios.get('https://eos.light-api.net/api/balances/eos/' + accountName).then(res => 
             { 

               if(res.status == "200")
               {

                    this.accountTokens = []

                    res.data.balances.map(item=>{

                      if(item.amount.substring(0,6) != '0.0000')
                      {
                          // this.accountTokens.push({
                          //   img:'notset',
                          //   contract:item.contract,
                          //   name:item.currency,
                          //   amount:item.amount,
                          //   key:`${item.contract}_${item.currency}` 
                          // })

                          if(item.contract != 'eosio.token')
                          {

                            this.accountTokens.push({
                            img:this.$getTokenImageUrl(item.contract,item.currency),
                            contract:item.contract,
                            name:item.currency,
                            amount:item.amount,
                            key:`${item.contract}_${item.currency}` 
                          })

                          }
                           
                      }
                    
                    })

                    if(this.accountTokens.length > 12)
                    {
                        var nowCount = 0
                        this.accountTokens.map((value,index) => {
                      
                        if(nowCount < 12)
                        {
                          this.accountShowTokens.push(value)
                          nowCount++
                        }

                      })

                      this.showAllTokenButton = true
                    }
                    else{

                      this.showAllTokenButton = false
                      this.accountShowTokens = this.accountTokens

                    }

                   this.allTokenCounts = this.accountTokens.length
                   
               }
               else
               {
               
               }
              
             })
             .catch(err => 
             { 
                
             })
             .finally(()=> {  
               this.tranLoading = false
             });
      },

      showAccountAllToken(){

        this.showAllTokenButton = false
        this.accountShowTokens = []
        this.accountShowTokens = this.accountTokens

      }

  },
  async mounted() {
      
      //console.log('mounted');
      this.timeZoneDiff = -(new Date().getTimezoneOffset()/60); //new Date().getTimezoneOffset()/60는 한국은 -9 나오는데 9를 더해야 하므로 -를 앞에 해준다.
      //Dec 20, 2021 01:32:06 AM
      //"2021-12-19T16:32:06.000Z",

      await this.getRamPrice()
      
      if(this.$store.state.mainAnchorLoginName == '')
      {
        this.getAccountInfo("eosio");
        //this.getAccountInfoFromEOSjs("eosio");
      }
      else{
        //this.getAccountInfoFromEOSjs(this.$store.state.mainAnchorLoginName);
        this.getAccountInfo(this.$store.state.mainAnchorLoginName);
        this.ramReceiver = this.$store.state.mainAnchorLoginName;
      }

       this.$vuetify.goTo(0);
 
  },

}

// Date.prototype.addHours= function(h){
//     this.setHours(this.getHours()+h);
//     return this;
// }

</script>
